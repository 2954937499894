<template>
  <div>
    <el-card v-if="!isnotQYWX">
      <div style="height:80px;background-color: #f8f8f9;" class = "flexRow betweenCenter" v-loading="pageloading">
        <div class = "flexRow">
          <div style="margin-left:15px">
            <img :src="imgApi+msg.img" style="width:46px;heigth:46px" alt="">
          </div>
          <div style="margin-left:10px;" class = "flexCol flexevenly">
            <div vlass = "theFont" style="font-size: 14px;font-weight:bold">{{msg.title}}</div>
            <div class = "theFont">{{msg.describe}}</div>
          </div>
        </div>
        <el-button type="primary" style="margin-right:15px" @click="jumplink">授权安装企店助手</el-button>
      </div>
      <img :src="imgApi+'/image/installationAssetstant2.png'" style="max-width:1119px;margin:30px 0px" alt="">
      <div>
        <div class = "smTitleFont">1/商家创建涨粉营销活动，设置活动员工、奖励与欢迎语</div>
        <img :src="imgApi+'/image/installationAssetstant3.png'" style="max-width:1563px;margin:10px 0px" alt="">
      </div>
      <div>
        <div class = "smTitleFont">2/下载活动码并投放</div>
        <img :src="imgApi+'/image/installationAssetstant4.png'" style="max-width:1224px;margin:10px 0px" alt="">
      </div>
      <div>
        <div class = "smTitleFont">3/客户扫描活动码，添加员工企业微信并领取奖励</div>
        <img :src="imgApi+'/image/installationAssetstant6.png'" style="max-width:1413px;margin:10px 0px" alt="">
      </div>
      <div>
        <div class = "smTitleFont">4/商家查看活动数据表现</div>
        <img :src="imgApi+'/image/installationAssetstant5.png'" style="max-width:1356px;margin:10px 0px" alt="">
      </div>
    </el-card>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import config from "@/config"
import{
  qyweixinsuiteauthinfo
}from "@/api/sv1.0.0"
export default {
  props:{
    msg:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data(){
    return {
      imgApi:config.IMG_BASE,
      pageloading:false,
      isnotQYWX:true,
    }
  },
  created () {
    this.getData()
  },
  methods:{
    async getData() {
      this.pageloading = true
      try {
        const res = await qyweixinsuiteauthinfo();
        if(res.IsSuccess){
          this.isnotQYWX = res.Result.IsFinishQyWeixinSuiteStp
        }
      }finally{
        this.pageloading = false
      }
		},
    jumplink(){
      this.$router.push({
        path:'/saleChannel/qyWechat'
      })
    },
  }
}
</script>

<style lang = "less" scoped>
  .cardfixed{ 
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 10px;
    left: 10px;
  }
  .overshow{
    overflow: hidden;
    overflow-y: auto;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
  }
  .betweenCenter{
    justify-content: space-between;
    align-items: center;
  }
  .flexCenter{
    align-items: center;
    justify-content:center
  }
  .titFont{
    font-size: 14px;
    color: #303133;
  }
  .theFont{
    font-size: 12px;
		color: #999;
		margin-top: 10px;
  }
  .smTitleFont{
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }
</style>