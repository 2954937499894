<template>
  <div>
    <installationAssetstant :msg="msg">
      <el-card>
        <div class = "flexRow">
          <div class = "flexRow" style="margin-right:10px">
            <div class ="keywordsFont">关键字：</div>
            <el-input v-model="keywords" placeholder="活动名称" style="width:200px"></el-input>
          </div>
          <div class = "flexRow" style="margin-right:10px">
            <div class ="keywordsFont">活动状态：</div>
            <el-select v-model="activeState" style="width:200px">
              <el-option v-for="(v,i) in activeStateList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" style="margin-right:10px" @click="currentPage=1,getTableList()">查询</el-button>
          <buttonPermissions :datas="'rosepowderpush'" style="margin-right:10px">
            <el-button type="primary" @click="edit({Id:0})">添加</el-button>
          </buttonPermissions>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tableList" v-loading="tableLoading">
            <el-table-column prop="ActivityName" label="活动名称" width="250">
              <template slot-scope="scope">
                <div class ="fontHidden1">{{scope.row.ActivityName}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="EmployeeCount" label="参与员工" width="150"></el-table-column>
            <el-table-column prop="RiseFansTarget" label="目标涨粉人数" width="150"></el-table-column>
            <el-table-column prop="" label="活动时间" width="400">
              <template slot-scope="scope">
                {{scope.row.ActivityStartTime}}至{{scope.row.ActivityEndTime}}
              </template>
            </el-table-column>
            <el-table-column prop="StateName" label="状态" ></el-table-column>
            <el-table-column prop="AddTime" label="创建时间"></el-table-column>
            <el-table-column prop="" label="操作" width="150">
              <template slot-scope="scope">
                <div v-if="scope.row.State==1">
                  <buttonPermissions :datas="'rosepowderedit'" style="margin-right:10px">
                    <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'rosepowderpromote'" style="margin-right:10px">
                    <el-button type="text" @click="promote(scope.row)">推广</el-button>
                  </buttonPermissions>
                  <buttonPermissions2 :datas="['rosepowdercopy','rosepowderdel']">
                    <el-dropdown>
                      <el-button type="text">更多</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <div class = "flexCol">
                          <buttonPermissions :datas="'rosepowdercopy'">
                            <el-dropdown-item>
                              <el-button type="text" @click="copy(scope.row)">复制</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                          <buttonPermissions :datas="'rosepowderdel'">
                            <el-dropdown-item>
                              <el-button type="text" style="color:#f56c6c" @click="del(scope.row)">删除</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </buttonPermissions2>
                </div>
                <div v-else-if="scope.row.State==2">
                  <buttonPermissions :datas="'rosepowderedit'" style="margin-right:10px">
                    <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'rosepowderdata'" style="margin-right:10px">
                    <el-button type="text" @click="data(scope.row)">数据</el-button>
                  </buttonPermissions>
                  <buttonPermissions2 :datas="['rosepowderpromote','rosepowdercopy','rosepowderstop']">
                    <el-dropdown>
                      <el-button type="text">更多</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <div class = "flexCol">
                          <buttonPermissions :datas="'rosepowderpromote'">
                            <el-dropdown-item>
                              <el-button type="text" @click="promote(scope.row)">推广</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                          <buttonPermissions :datas="'rosepowdercopy'">
                            <el-dropdown-item>
                              <el-button type="text" @click="copy(scope.row)">复制</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                          <buttonPermissions :datas="'rosepowderstop'">
                            <el-dropdown-item>
                              <el-button type="text" style="color:#f56c6c" @click="stop(scope.row)">使终止</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </buttonPermissions2>
                </div>
                <div v-else-if="scope.row.State==3">
                  <buttonPermissions :datas="'rosepowderlook'" style="margin-right:10px">
                    <el-button type="text" @click="look(scope.row)">查看</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'rosepowderdata'" style="margin-right:10px">
                    <el-button type="text" @click="data(scope.row)">数据</el-button>
                  </buttonPermissions>
                  <buttonPermissions2 :datas="['rosepowdercopy','rosepowderdel']">
                    <el-dropdown>
                      <el-button type="text">更多</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <div class = "flexCol">
                          <buttonPermissions :datas="'rosepowdercopy'">
                            <el-dropdown-item>
                              <el-button type="text" @click="copy(scope.row)">复制</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                          <buttonPermissions :datas="'rosepowderdel'">
                            <el-dropdown-item>
                              <el-button type="text" style="color:#f56c6c" @click="del(scope.row)">删除</el-button>
                            </el-dropdown-item>
                          </buttonPermissions>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </buttonPermissions2>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align:right;margin-top:10px" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="sizepage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-card>
    </installationAssetstant>

    <qr-code :downloadData="windowCode" :qrCodeIshow="qrCodeIshow" :title="'下载活动二维码'" :downFont="'下载活动二维码'" :isNeedExchangeToQRCode="false"
    	@cancel="qrCodeIshow = false"></qr-code>
  </div>
</template>

<script>
import installationAssetstant from "./components/installationAssetstant"
import buttonPermissions from '@/components/buttonPermissions';
import buttonPermissions2 from '@/components/buttonPermissions2';
import QrCode from "@/components/bathQrCodes"
import {
  qyWeixinRiseFansMarketinglist,
  qyWeixinRiseFansMarketingqyWeixinRiseFansMarketingQrCode,
  qyWeixinRiseFansMarketingdelete,
  qyWeixinRiseFansMarketingabort
}from "@/api/sv3.0.0"
import {
  mapGetters
} from "vuex"
export default {
  components:{
    installationAssetstant,
    buttonPermissions,
    buttonPermissions2,
    QrCode
  },
  data () {
    return {
      msg:{
        title:'涨粉营销',
        img:'/image/installationAssetstant1.png',
        describe:'添加员工企业微信，获得优惠券奖励或转盘抽奖次数的形式，助力企微涨粉。',
      },
      keywords:'',
      activeState:null,
      activeStateList:[
        {value:null,label:'全部'},
        {value:1,label:'未开始'},
        {value:2,label:'进行中'},
        {value:3,label:'已结束'},
      ],
      tableList:[],
      tableLoading:false,
      currentPage:1,
      sizepage:20,
      total:null,

      qrCodeIshow:false,
      windowCode:{
        url: '',
				icon: '',
				name: '',
      },
    }
  },
  computed: {
		...mapGetters(['pcImg'])
	},
  created () {
    this.getTableList()
  },
  methods: {
    data(row){
      this.$router.push({
        path:'/Customer/rosepowder/data',
        query:{
          Id:row.Id,
        }
      })
    },
    look(row){
      this.$router.push({
        path:'/Customer/rosepowder/detail',
        query:{
          Id:row.Id,
          look:true
        }
      })
    },
    stop(row){
      this.$confirm(`是否确认终止涨粉营销活动[${row.ActivityName}]？活动终止后，你将不可再对这场活动进行编辑；客户再次通过该活动码添加员工企业微信，无法继续获得活动权益。是否确认终止？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tostop(row)
      }).catch(() => {});
    },
    async tostop(row){
      this.tableLoading = true
      try{
        let msg = await row
        let data = {
          Id:msg.Id
        }
        let res = await qyWeixinRiseFansMarketingabort(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getTableList()
        }
      }finally{
        this.tableLoading = false
      }
    },
    del(row){
      this.$confirm(`是否确认删除涨粉营销活动[${row.ActivityName}]？删除后不可恢复。`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.todel(row)
      }).catch(() => {});
    },
    async todel(row){
      this.tableLoading = true
      try{
        let msg = await row
        let data = {
          Id:msg.Id
        }
        let res = await qyWeixinRiseFansMarketingdelete(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getTableList()
        }
      }finally{
        this.tableLoading = false
      }
    },
    copy(row){
      this.$router.push({
        path:'/Customer/rosepowder/detail',
        query:{
          Id:row.Id,
          IsCopy:true
        }
      })
    },
    async promote(row){
      try{
        this.tableLoading = true
        let res = await qyWeixinRiseFansMarketingqyWeixinRiseFansMarketingQrCode({QyWeixinRiseFansMarketingId:row.Id})
        if(res.IsSuccess){
          this.windowCode = {
            url: res.Result.QrCode,
            icon: this.pcImg,
            name: row.ActivityName,
          }
          this.qrCodeIshow = true
        }
      }finally{
        this.tableLoading = false
      }

      
    },
    edit(row){
      // console.log(row)
      this.$router.push({
        path:'/Customer/rosepowder/detail',
        query:{
          Id:row.Id
        }
      })
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.getTableList()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getTableList()
    },
    async getTableList(){
      try{
        this.tableLoading = true
        let data = {
          Keywords:this.keywords,
          State:this.activeState,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage,
        }
        let res = await qyWeixinRiseFansMarketinglist(data)
        if(res.IsSuccess){
          this.tableList = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableLoading = false
      }
    }
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.flexCol{
  display: flex;
  flex-direction: column;
}
.keywordsFont{
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  white-space: nowrap;
}
.fontHidden1{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>